import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, navigate, withPrefix } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import { useMediaPredicate } from "react-media-hook"
import Slider from "react-slick"
import Select from "react-select"
import ReactMarkdown from "react-markdown/with-html"

import "../../../graphql/fragments"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"

import { Section, Container } from "../../../components/grid/grid"
import ans6 from "../../../assets/img/faq-infinite-living.jpg"
import desktopLp from "../../../assets/img/infinite-living-mobile.jpg"
import icLocationBlack from "../../../assets/img/icons/icLocationBlack.png"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const changeParentSlug = (slug) => {
  switch(slug) {
    case "infiniteliving-shophouse":
      slug = "shophouse";
      break;
    case "infiniteliving-residential":
      slug = "residential";
      break;
    case "infiniteliving-kavling":
      slug = "kavling";
      break;
    case "infiniteliving-apartment":
      slug = "apartment";
      break;
  }
  return slug
}

const InfiniteLivingFaqPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        propertyType(id: "infiniteliving-faq", idType: SLUG) {
          acfPropertyType {
            sliderImageBanner {
              link
              image {
                sourceUrl
              }
            }
            sliderImageMobile {
              link
              image {
                sourceUrl
              }
            }
            sliderImageBannerWdesc {
              ... on WordPress_PropertyType_Acfpropertytype_SliderImageBannerWdesc_Content {
                title
                description
                image {
                  sourceUrl
                }
                link {
                  url
                }
              }
            }
            sliderImageMobileWdesc {
              ... on WordPress_PropertyType_Acfpropertytype_SliderImageMobileWdesc_Content {
                title
                description
                image {
                  sourceUrl
                }
                link {
                  url
                }
              }
            }
          }
        }
        locations(first: 100) {
          nodes {
            locationId
            slug
            name
          }
        }
        propertyTypes(first: 150) {
          nodes {
            name
            slug
            propertyTypeId
            children {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  const [filteredLocations, setFilteredLocations] = useState("")
  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState("")

  const wordPress = staticData.wordPress.propertyType
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const listPropertyTypes = staticData.wordPress.propertyTypes.nodes
  const listLocations = staticData.wordPress.locations.nodes
  const thisCurrrentUrl = process.env.BASE_URL + location.pathname

  let listOfImages = []
  let listImagesBanner = wordPress?.acfPropertyType?.sliderImageBannerWdesc
  let listImagesMobile = wordPress?.acfPropertyType?.sliderImageMobileWdesc

  const allProperty = { label: "All Property", value: " " }
  const allLocations = { label: "All Locations", value: " " }
  let listPropertyTypesParent = []
  let locations = []

  listPropertyTypes?.map(listItems => {
    if(listItems.slug === "infiniteliving"){
      listItems?.children?.nodes?.map(item => {
        let slug = changeParentSlug(item.slug)
        listPropertyTypesParent.push({label: item.name, value: slug})
      })
    }
  })

  listLocations?.map((item) =>
    item.slug !== "international-projects" && 
    item.slug !== "bekasi" && 
    item.slug !== "bogor" && 
    item.slug !== "cikarang" && 
    item.slug !== "depok" && 
    item.slug !== "karawang" && 
    item.slug !== "medan" && 
    item.slug !== "semarang" && 
      locations.push({ value: item.slug, label: item.name })
  )

  listPropertyTypesParent.splice(0, 0, allProperty)
  locations.splice(0, 0, allLocations)

  if(!resizeScreen){
    listImagesBanner &&
      listImagesBanner.map(item => {
        listOfImages.push({
          image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
          url: item?.link?.url ? item?.link?.url : "#",
          title: item?.title ? item.title : "",
          description : item?.description ? item?.description : ""
        })
      })
  }else{
    listImagesMobile &&
      listImagesMobile.map(item => {
        listOfImages.push({
          image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
          url: item?.link?.url ? item?.link?.url : "#",
          title: item?.title ? item.title : "",
          description : item?.description ? item?.description : ""
        })
      })
  }

  const settings = {
    autoplay: listOfImages.length > 1 ? true : false,
    autoplaySpeed: listOfImages.length > 1 ? 3000 : 0,
    accessibility: true,
    dots: true,
    dotsClass: "slick-dots dots-lg",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const locationHandler = e => {
    setFilteredLocations(e)
  }
  const propertyTypesHandler = e => {
    setFilteredPropertyTypes(e)
  }

  useEffect(() => {
    if(filteredPropertyTypes.value !== undefined && filteredLocations.value !== undefined){
      navigate("/infiniteliving/"+filteredPropertyTypes.value+"/"+filteredLocations.value)
    }else if(filteredPropertyTypes.value !== undefined && filteredLocations.value === undefined){
      if(filteredPropertyTypes.value === " "){
        navigate("/infiniteliving/")
      }else{
        navigate("/infiniteliving/"+filteredPropertyTypes.value, {
          state: {
            propertyType: filteredPropertyTypes.value,
          },
        })
      }
    }else if(filteredPropertyTypes.value === undefined && filteredLocations.value !== undefined){
      if(filteredLocations.value === " "){
        navigate("/infiniteliving/")
      }else{
        navigate("/infiniteliving/all-property/"+filteredLocations.value, {
          state: {
            location: filteredLocations.value,
          },
        })
      }
    }
  }, [filteredPropertyTypes, filteredLocations])

  useEffect(() => {
    let listOfImages = []
    let listImagesBanner = wordPress?.acfPropertyType?.sliderImageBannerWdesc
    let listImagesMobile = wordPress?.acfPropertyType?.sliderImageMobileWdesc

    if(!resizeScreen){
      listImagesBanner &&
        listImagesBanner.map(item => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item.title : "",
            description : item?.description ? item?.description : ""
          })
        })
      document.querySelector(".animated").style.marginTop = "0px"
    }else{
      listImagesMobile &&
        listImagesMobile.map(item => {
          listOfImages.push({
            image: item?.image?.sourceUrl ? item?.image?.sourceUrl : "",
            url: item?.link?.url ? item?.link?.url : "#",
            title: item?.title ? item.title : "",
            description : item?.description ? item?.description : ""
          })
        })
      document.querySelector(".animated").style.marginTop = "60px"
    }

    // document.querySelector(".animated").style.marginTop = "0px"
    // document.querySelector(".sliderWfh").style.paddingTop = "0px"
    // document.querySelector(".sliderWfh").style.paddingBottom = "0px"
    document.querySelector("#lang-wrapper").classList.remove('d-flex')
    document.querySelector("#lang-wrapper").classList.add('d-none')
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
      
    if(resizeScreen){
      document.querySelector("#navbarMobile .logo-secondary").style.cssText = `display:none !important`
      document.querySelector("#navbarMobile .logo-main").style.cssText = `display:block !important`
    }else{
      document.querySelector("#navbarDesktop .logo-secondary").style.cssText = `display:none !important`
      document.querySelector("#navbarDesktop .logo-main").style.cssText = `display:block !important`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }
  }, [])

  // useEffect(() => {
  //   const clicker1 = document.getElementById("ach1")
  //   const clicker2 = document.getElementById("ach2")
  //   const clicker3 = document.getElementById("ach3")
  //   const clicker4 = document.getElementById("ach4")
  //   const clicker5 = document.getElementById("ach5")
  //   const clicker6 = document.getElementById("ach6")
  //   const clicker7 = document.getElementById("ach7")

  //   if(resizeScreen){
  //     clicker1.addEventListener('click', function(){
  //       window.scrollTo(0,800)
  //     })
  
  //     clicker2.addEventListener('click', function(){
  //       window.scrollTo(0,870)
  //     })
  
  //     clicker3.addEventListener('click', function(){
  //       window.scrollTo(0,940)
  //     })
  
  //     clicker4.addEventListener('click', function(){
  //       window.scrollTo(0,1010)
  //     })
      
  //     clicker5.addEventListener('click', function(){
  //       window.scrollTo(0,1070)
  //     })
  
  //     clicker6.addEventListener('click', function(){
  //       window.scrollTo(0,1130)
  //     })
  
  //     clicker7.addEventListener('click', function(){
  //       window.scrollTo(0,1195)
  //     })
  
  //   }else{
  //     clicker1.addEventListener('click', function(){
  //       window.scrollTo(0,655)
  //     })
  
  //     clicker2.addEventListener('click', function(){
  //       window.scrollTo(0,750)
  //     })
  
  //     clicker3.addEventListener('click', function(){
  //       window.scrollTo(0,836)
  //     })
  
  //     clicker4.addEventListener('click', function(){
  //       window.scrollTo(0,910)
  //     })
      
  //     clicker5.addEventListener('click', function(){
  //       window.scrollTo(0,988)
  //     })
  
  //     clicker6.addEventListener('click', function(){
  //       window.scrollTo(0,1052)
  //     })
  
  //     clicker7.addEventListener('click', function(){
  //       window.scrollTo(0,1135)
  //     })
  
  //   }

  // }, [])

  return (
    <Layout location={location} currentLocation={location.pathname}>
      <SEO 
        title={`Infinite Living | FAQ Sinar Mas Land`}
        fbTitle={`Infinite Living | FAQ Sinar Mas Land`}
        twitterTitle={`Infinite Living | FAQ Sinar Mas Land`}
        twitterDescription={`Infinite Living adalah Program Penjualan Nasional dari Sinar Mas Land. Program ini berlangsung dari bulan Januari sampai Desember 2023.`}
        fbDescription={`Infinite Living adalah Program Penjualan Nasional dari Sinar Mas Land. Program ini berlangsung dari bulan Januari sampai Desember 2023.`}
        description={`Infinite Living adalah Program Penjualan Nasional dari Sinar Mas Land. Program ini berlangsung dari bulan Januari sampai Desember 2023.`}
        url={thisCurrrentUrl}
        fbImg={listImagesMobile[0]?.image?.sourceUrl ? listImagesMobile[0]?.image?.sourceUrl : desktopLp}
        twitterImg={listImagesMobile[0]?.image?.sourceUrl ? listImagesMobile[0]?.image?.sourceUrl : desktopLp}
        img={listImagesMobile[0]?.image?.sourceUrl ? listImagesMobile[0]?.image?.sourceUrl : desktopLp}
      />
      <Helmet>
        <script src={withPrefix("slider.js")} type="text/javascript" />
        <body className="bd-page" />
      </Helmet>
      <Section className="header-smartmove-banner p-0" style={{padding:"0px"}}>
        <Slider {...settings}>
          {listOfImages.length > 0 &&(
            listOfImages.map(item => {
              return(
                <div>
                  <img className="slider-img-smartmove" src={item.image} onClick={()=> window.open(item.url, "_blank")}/>
                  <Section>
                    <Container className="container-md">
                      <h2>{item.title}</h2>
                      <ReactMarkdown key={item.description} source={item.description} escapeHtml={false} />
                    </Container>
                  </Section>
                </div>
              )
            })
          )}
        </Slider>
      </Section>
      <Section className="main-faq">
        <Container className="mb-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-landmark form-icon-fa"></i>
                <Select
                  id="propertytypes"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Property Types"
                  options={listPropertyTypesParent}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => propertyTypesHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group d-flex align-items-center">
                <img alt="Location Icon" className="form-icon" src={icLocationBlack} />
                <Select
                  id="location"
                  className="form-select w-100"
                  classNamePrefix="select"
                  placeholder="Location"
                  options={locations}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => locationHandler(e)}
                />
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <Accordion preExpanded={[1]} allowZeroExpanded>
            <AccordionItem uuid={1}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  FAQ Infinite Living Sinarmasland
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Accordion allowZeroExpanded>
                  <AccordionItem id="aci1">
                    <AccordionItemHeading id="ach1">
                      <AccordionItemButton>
                        1.Apa itu Infinite Living?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Infinite Living adalah program penjualan dari Sinar Mas Land yang menawarkan berbagai kemudahan dan keringanan bagi para konsumen. Program ini berlaku untuk berbagai proyek properti Sinar Mas Land mulai dari 1 Maret sampai akhir Desember 2024.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci2">
                    <AccordionItemHeading id="ach2">
                      <AccordionItemButton>
                        2. Kapan periode Infinite Living berlangsung?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Program Infinite Living berlangsung mulai dari 1 Maret – 31 Desember 2024
                        <br />
                        Periode 1 : 1 Mar – 30 Jun 2024
                        <br />
                        Periode 2 : 1 Jul – 30 Sep 2024
                        <br />
                        Periode 3 : 1 Okt – 31 Des 2024
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci3">
                    <AccordionItemHeading id="ach3">
                      <AccordionItemButton>
                        3. Berapa besar keringanan yang ditawarkan pada periode 1?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div class="lg:mx-5">
                        <p class="mb-3 font-weight-bold">Untuk Kavling</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Periode 1 (Mar – Jun 2023)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Hard Cash DP 20%</td>
                              <td class="border px-4 py-2">
                                - Discount 25%<br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPT Express DP 20%<br /> (Harga max Rp 2.5M)</td>
                              <td class="border px-4 py-2"> 
                                - Subsidi DP 20% <br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPT Express DP 20%</td>
                              <td class="border px-4 py-2"> 
                                - Discount 24%<br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPT Express DP 15%</td>
                              <td class="border px-4 py-2"> 
                                - Discount 5%<br/>
                                - Subsidi DP 15% <br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Installment 12x</td>
                              <td class="border px-4 py-2"> 
                                - Discount 18%<br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p>
                          <span className="text-underline">Notes:</span><br/>
                          <span>*) Jika BPHTB tidak eligible : Free BPHTB dapat dialihkan menjadi discount <span className="font-weight-bold">3.5%</span></span><br/>
                          <span>- Free 1x Admin “Biaya Pengalihan”.</span><br/>
                          <span>- KPT Express DP 20% Subsidi 20%, berlaku untuk transaksi penjualan dengan harga pengikatan max Rp 2.5 Milyar (inc PPN) / unit.</span><br/>
                          <span>- Free BPHTB diberikan jika kavling sudah memiliki sertifikat pecahan dan AJB dalam waktu max 1 tahun setelah BAST. </span><br/>
                          <span>- Tambahan disc + 5% untuk Kav Irregular / Tusuk Sate</span><br/>
                        </p>
                        <p class="mb-1 font-weight-bold">Untuk Non Apartment</p>
                        {/* <p class="mb-3 text-xs">
                          Alesha, Asatti, Anarta,Casa De Parco-Magnolia, Casa De Parco-Cassea, West Park, Landed Phase 1 Batam, Kios Pasar Modern 2/Barat, NGS-Garden Suite, Kios Autoparts, Ruko Pasar Modern Barat, The Eminent Vicacia, The Eminent Ingenia*
                        </p> */}
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Category 2</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Category 3</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Category 4</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Hard Cash</td>
                              <td class="border px-4 py-2">
                                - Discount 24%<br/>
                                - Free BPHTB*
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 13%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 12%<br/>
                                - Hadiah Konsumen 2%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPT Express DP 10%</td>
                              <td class="border px-4 py-2">
                                - Discount 12% <br/>
                                - Subsidi DP 10% <br/>
                                - Free BPHTB*
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 1.5%<br/>
                                - Subsidi DP 10%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 3.5%<br/>
                                - Subsidi DP 10%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Product Category 2 : </span><br/>
                          <span>Alesha, Assana, Whitsand, Ruko Provence Suites, Autoparts, Kios Pasar Modern 2/Barat, Kios Pasar Modern TBW, Zena, Pineville (Balikpapan), Garden Suite (Surabaya), Landed P.1 (Blok ABCD) (Batam)</span><br/>

                          <span className="text-underline">Product Category 3 : </span><br/>
                          <span>Alfiore, Myza Flathouse, Freja House, Freja Suites (termasuk Kavling), Visana at The, Savia, Quantis, Aether, Tanakayu – Jiva , Tanakayu – Svani, Tanakayu – Svadhi, Tanakayu – Svasti, Fleekhauz, Fleekhauz R, Imajihaus,Caelus, Azura Starlight, Ruko A6 (Balikpapan), Kalvling P.2 (Batam), Landed P.2 (Blok EFGH) (Batam), Landed P.1 (Blok ABCD) Indent (Batam)</span><br/>

                          <span className="text-underline">Product Category 4 : </span><br/>
                          <span>Ruko Greenwich, Ruko The Loop, Taman Tekno X, Ruko Northridge, Ruko Madrid X, Ruko Pasar Modern Timur 2, Breezy House, Yuthica, Virginia Arcade, Cozmo House Ext, The Blizfield, Latinos Business District, Grand Freja, Ruko The Savia, Askara Nue, Cheville (Balikpapan), Nordville (Balikpapan), Ruko New Palladium (Balikpapan), Hayfield (Balikpapan), Cloverville Kavling (Surabaya), Paddington (Surabaya), Ruko Madison (Cibubur) , Atherton (Cibubur) , Mississippi (Cibubur), Vermont (Cibubur).</span><br/>
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Category</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Additional Discount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold text-center">2</td>
                              <td class="border px-4 py-2">
                                + 10% untuk Kios Autoparts BSD
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold text-center">3</td>
                              <td class="border px-4 py-2"> 
                                + 5% untuk The Nove Landed Phase 1 &2 (indent) <br />
                                + 2.5% untuk Myza Flathouse <br />
                                + 2.5% untuk TBW Alfiore
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold text-center">4</td>
                              <td class="border px-4 py-2"> 
                                + 5% untuk Latinos Business District <br />
                                + 5% untuk Cloverville Kavling
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p class="mb-1 font-weight-bold">Untuk Apartment</p>
                        <p class="mb-3 font-weight-bold">
                          Promo Category 5A - The Elements
                        </p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5A: The Elements</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Hard Cash</td>
                              <td class="border px-4 py-2">
                                - Discount 10% <br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPA Express DP 10%</td>
                              <td class="border px-4 py-2">
                                - Discount 10% <br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span><br/>
                          <span>*) Untuk unit dengan PPN DTP, Free BPHTB dapat dialihkan menjadi discount 4%</span><br/>
                          <span>- Tambahan disc +  2% : Tower 1 Unit D dan Tower 2 Unit A.</span><br/>
                          <span>- Tambahan disc +  4% : Tower 1 Unit C dan Tower 2 Unit B.</span><br/>
                        </p>
                        <p class="mb-1 font-weight-bold">Promo Category 5B dan C - Southgate (Altuera, Elegance dan Prime)</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5B: Altuera</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5C: Elegance & Prime</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Hard Cash</td>
                              <td class="border px-4 py-2">
                                - Discount 18% <br/>
                                - Free Furniture 3% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 17.5% <br/>
                                - Free Furniture 3% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPA Express DP 15%</td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 15% <br/>
                                - Free KPA Fee 1.5% <br/>
                                - Free Furniture 3% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 15% <br/>
                                - Free Furniture 3% <br/>
                                - Free KPA Fee 1.5% <br/>
                                - Voucher Samsung 0.5% <br/>
                                - Free BPHTB
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Installment DP 40%-2x, Sisa cicil 12X</td>
                              <td class="border px-4 py-2">
                                - Discount 12.5% <br/>
                                - Free Furniture 3% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 10% <br/>
                                - Free Furniture 3% <br/>
                                - Voucher Samsung 0.5% <br/>
                                - Free BPHTB
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span><br/>
                          <span>- SG Altuera: Tambahan disc +3% untuk Unit Non-Studio.</span><br/>
                          <span>- SG Elegance: Tambahan disc +3% untuk Unit B,C,F, dan K.</span><br/>
                          <span>- SG Prime : Tambahan disc +6% untuk Unit B, F, dan G ; tambahan disc +7.5% untuk Unit A, C, dan D</span><br/>
                        </p>
                        <p class="mb-1 font-weight-bold">Promo Category 5D - Aerium</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5D: Aerium</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Hard Cash</td>
                              <td class="border px-4 py-2">
                                - Discount 21% <br/>
                                - Free Service Charge (12 bulan) <br/>
                                - Free Furniture 5% <br/>
                                - Hadiah Customer 1% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPA Express DP 15%</td>
                              <td class="border px-4 py-2">
                                - Discount 3.5% <br/>
                                - Subsidi DP 15% <br/>
                                - Free Service Charge (12 bulan) <br/>
                                - Free Furniture 5% <br/>
                                - Hadiah Customer 1% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Installment 12x</td>
                              <td class="border px-4 py-2">
                                - Discount 16% <br/>
                                - Free Service Charge (12 bulan) <br/>
                                - Free Furniture 5% <br/>
                                - Hadiah Customer 1% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Installment 20% 2x;<br/>80% 36x</td>
                              <td class="border px-4 py-2">
                                - Discount 5% <br/>
                                - Free Service Charge (12 bulan) <br/>
                                - Free Furniture 5% <br/>
                                - Hadiah Customer 1% <br/>
                                - Voucher Samsung 0.5%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span><br/>
                          <span>- Tambahan disc +3%  untuk unit #9 (2BR-C) dan #10 (2BR-B)</span><br/>
                        </p>
                        <p class="mb-1 font-weight-bold">Promo Category 5E dan 5F - Batam (Kalani dan Kaina)</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5E: Kalani</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5F: Kaina</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Hard Cash</td>
                              <td class="border px-4 py-2">
                                - Discount 11.5% <br/>
                                - Free Service Charge (12 bulan) <br/>
                                - Free BPHTB <br/>
                                - Hadiah Customer 1.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 11% <br/>
                                - Free Service Charge (12 bulan) <br/>
                                - Free BPHTB <br/>
                                - Furniture 10%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPA Express DP 10%</td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 10% <br/>
                                - Free BPHTB <br/>
                                - Hadiah Customer 1.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 10% <br/>
                                - Free BPHTB <br/>
                                - Furniture 10%
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Installment 6x</td>
                              <td class="border px-4 py-2">
                                - Discount 9% <br/>
                                - Free BPHTB <br/>
                                - Hadiah Customer 1.5%
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 9% <br/>
                                - Free BPHTB <br/>
                                - Furniture 10%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p class="mb-1 font-weight-bold">Promo Category 5G - BSD City (Asatti dan Casa de Parco)</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5G: Asatti</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5G: Casa de Parco</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Hard Cash</td>
                              <td class="border px-4 py-2">
                                - Discount 22%<br/>
                                - Free BPHTB*
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 27%<br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPA Express DP 10%</td>
                              <td class="border px-4 py-2">
                                - Discount 11.5%<br/>
                                - Subsidi DP 10%<br/>
                                - Free BPHTB*
                              </td>
                              <td class="border px-4 py-2">
                                - Discount 16.5%<br/>
                                - Subsidi DP 10%<br/>
                                - Free BPHTB*
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span><br/>
                          <span>*) Jika BPHTB tidak eligible : Free BPHTB dapat dialihkan menjadi discount 3.5%</span><br/>
                        </p>
                        <p class="mb-1 font-weight-bold">Promo Category 5H - Surabaya (Klaska)</p>
                        <table class="table table-responsive mb-4">
                          <thead>
                            <tr>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">Term of Payment</th>
                              <th class="text-left border px-4 py-2 no-wrap-wspace">5H: Klaska</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">KPA Express DP 10%</td>
                              <td class="border px-4 py-2">
                                - Subsidi DP 10%<br/>
                                - Furniture 10%<br/>
                                - Free Service Charge (6 bulan)<br/>
                                - Hadiah Customer 1.1%<br/>
                                - Free BPHTB
                              </td>
                            </tr>
                            <tr>
                              <td class="border px-4 py-2 font-weight-bold">Installment 6x</td>
                              <td class="border px-4 py-2">
                                - Discount 7.5%<br/>
                                - Furniture 10%<br/>
                                - Free Service Charge (6 bulan)<br/>
                                - Hadiah Customer 1.1%<br/>
                                - Free BPHTB
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <p className="pb-4">
                          <span className="text-underline">Notes:</span><br/>
                          <span>- Tambahan disc +3% untuk Deluxe – 1 Bedroom.</span><br/>
                        </p>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci4">
                    <AccordionItemHeading id="ach4">
                      <AccordionItemButton>
                        4. Proyek apa saja yang ditawarkan dalam program Infinite Living ? Dan dimana lokasinya ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      BSD City residential dan commercial, Kota Wisata & Legenda Wisata - Cibubur, Nuvasa Bay - Batam, Klaska, Paddington & Wisata Bukit Mas - Surabaya, Grand City Balikpapan - Balikpapan, The Elements - Jakarta, Aerium - Jakarta, Southgate – Jakarta, Taman Banjar Wijaya Tangerang.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci5">
                    <AccordionItemHeading id="ach5">
                      <AccordionItemButton>
                        5. Jenis Produk apa saja yang ditawarkan dalam program Infinite Living ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Non Kavling, Kavling, Apartment dan Ruko.</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci6">
                    <AccordionItemHeading id="ach6">
                      <AccordionItemButton>
                        6. Bagaimana cara mendapatkan keringanan selama program
                        Infinite Living?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <img src={ans6} style={{ width: "100%" }} />
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci7">
                    <AccordionItemHeading id="ach7">
                      <AccordionItemButton>
                        7. Di platform mana saja dapat melakukan pembelian produk program Infinite Living ?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                      Bisa dengan mengunjungi <a href="/infiniteliving">www.sinarmasland.com/infiniteliving</a> , menghubungi property agent/sales marketing Sinar Mas Land atau kunjungi social media IG @sinarmas_land , FB Sinar Mas Land. <br/>
                      Selain itu, dapat juga bertransaksi melalui e-Commerce yang sudah bekerja sama dengan Sinar Mas Land seperti Tokopedia dan Blibli
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Section>
    </Layout>
  )
}
export default InfiniteLivingFaqPage
